<template>
  <b-modal size="md" v-model="show" title="Switch Biller" hide-footer @hide="onHide">
    <el-descriptions class="margin-top mb-3" title="" :column="1" border>
      <el-descriptions-item>
        <template slot="label">
          <font-awesome-icon icon="tag"/>
          Product
        </template>
        {{ selectedProduct.denom_name }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <font-awesome-icon icon="handshake"/>
          Assgined Biller
        </template>
        {{ selectedProduct.active_biller_name }}
      </el-descriptions-item>
    </el-descriptions>
    <el-form ref="form" label-width="80px" label-position="left">
      <el-form-item label="Vendor">
        <el-select size="small" v-model="form.vendor_id" @change="handlerOnChangeVendor" class="w-full">
          <el-option v-for="item in vendors" :key="item.id" :value="item.vendor_id" :label="item.vendor_name"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Product">
        <el-select filterable :loading="loading.search_product" remote :remote-method="searchProduct" :disabled="!form.vendor_id" size="small" v-model="form.v_price_id" class="w-full">
          <el-option v-for="item in products" :key="item.id" :value="item.id" :label="item.product_name">
            <span style="float: left">{{ item.product_name }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.product_code }}</span>
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div class="d-flex flex-row float-right">
      <el-button @click="onSubmit" :loading="loading.submit" class="mr-2 ml-2" size="small" type="primary">Submit</el-button>
      <el-button @click="onHide" size="small" class="mr-2">Cancel</el-button>
    </div>
  </b-modal>
</template>
<script>
import {
  VOUCHER_VENDOR,
} from '@/store/modules/vouchers';
import {
  GET_PPOB_BY_VENDOR,
} from '@/store/modules/ppob';

export default {
  name: 'SwitchBiller',
  props: {
    isShown: {
      type: Boolean,
      default: false,
    },
    selectedProduct: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      show: false,
      form: {
        vendor_id: '',
        v_price_id: '',
      },
      loading: {
        search_product: false,
        submit: false,
      },
      vendors: [],
      products: [],
      timeout: {
        search_product: null,
      },
      search_keyword: '',
    };
  },
  methods: {
    async getVoucherVendor() {
      await this.$store.dispatch(VOUCHER_VENDOR, {});
      this.vendors = this.$store.getters.vendors.rows;
    },
    handlerOnChangeVendor() {
      this.form.v_price_id = '';
      this.search_keyword = '';
      this.getProduct();
    },
    searchProduct(value) {
      clearTimeout(this.timeout.search_product);
      this.timeout.search_product = setTimeout(() => {
        this.search_keyword = value;
        this.getProduct();
      }, 1000);
    },
    async getProduct() {
      this.loading.search_product = true;
      const { data } = await this.$store.dispatch(GET_PPOB_BY_VENDOR, {
        id: this.form.vendor_id,
        query: {
          search_keyword: this.search_keyword,
          per_page: 50,
        },
      });
      this.loading.search_product = false;
      this.products = data.rows.map((v) => {
        if (this.form.vendor_id.toString() !== '1') {
          if (v.provider && v.nominal) {
            v.product_name = [v.provider, v.nominal].join(' ');
          } else {
            v.product_name = v.nominal;
          }
        } else {
          v.product_name = v.product_code;
        }
        return v;
      });
    },
    onHide() {
      this.form = {
        vendor_id: '',
        v_price_id: '',
      };
      this.$emit('onHide');
    },
    onSubmit() {
      this.$confirm('Are you want to update this product?', {
        type: 'info',
        title: 'Confirmation',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            this.$emit('onSubmit', {
              id: this.selectedProduct.id,
              data: {
                v_price_id: this.form.v_price_id,
              },
            });
            this.onHide();
          }
          done();
        },
      }).catch(() => {});
    },
  },
  watch: {
    isShown() {
      this.show = this.isShown;
      if (this.show) {
        this.getVoucherVendor();
      }
    },
  },
};
</script>
